<template>
  <div class="main">
    <b-pagination-nav
      v-model="localCurrentPage"
      v-show="totalPage >= 2"
      class="custom-pagination"
      :link-gen="linkGen"
      :number-of-pages="numberOfPages"
      use-router
      @change="handlePageChange"
      align="right"
      first-class="page-item-first btn btn-icon btn-sm mr-.5 my-1"
      prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
      next-class="page-item-next btn btn-icon btn-sm mr-.5 my-1 ml-.5"
      last-class="page-item-last btn btn-icon btn-sm my-1 "
      page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
    >
      <template v-slot:first-text>
        <span>
          <i class="fa-solid fa-angles-left fa-sm"></i>
        </span>
      </template>

      <template v-slot:prev-text>
        <i class="fa-solid fa-angle-left fa-sm"></i>
      </template>

      <template v-slot:next-text>
        <i class="fa-solid fa-angle-right fa-sm"></i>
      </template>

      <template v-slot:last-text>
        <span class="text-info">
          <i class="fa-solid fa-angles-right fa-sm"></i>
        </span>
      </template>
    </b-pagination-nav>
  </div>
</template>

<script>
export default {
  props: {
    numberOfPages: {
      type: Number,
      required: true,
      default: () => 1,
    },
    currentPage: {
      type: [Number, String],
      required: true,
      default: () => 1,
    },
    totalPage: {
      type: Number,
      required: true,
      default: () => 0,
    },
    linkParams: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      localCurrentPage: this.currentPage,
    };
  },
  watch: {
    currentPage(newVal) {
      this.localCurrentPage = newVal;
    },
  },
  created() {},
  methods: {
    linkGen(pageNum) {
      const link = Object.assign({ page: pageNum }, { ...this.linkParams });
      return {
        path: '',
        query: link,
      };
    },
    handlePageChange(pageNum) {
      this.localCurrentPage = pageNum;
      this.$emit('page-change', pageNum);
    },
  },
};
</script>

<style lang="scss" scoped></style>
